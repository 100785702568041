<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <v-tabs v-model="currentTab" fixed-tabs>
      <v-tab v-for="tab in tabs" :key="tab.key" :href="'#tab-' + tab.key">
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item value="tab-tool">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('tools.name')"
                  >
                    <v-text-field
                      ref="name"
                      v-model="tool.name"
                      :label="$t('tools.name')"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('tools.type')"
                  >
                    <v-select
                      v-model="tool.type"
                      :items="types"
                      item-value="name"
                      item-text="tName"
                      :menu-props="{ maxHeight: '400' }"
                      :hint="$t('select-hint')"
                      :label="$t('tools.type')"
                      persistent-hint
                      dense
                      :error-messages="errors"
                      :disabled="tool.from_orders"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('tools.client')"
                  >
                    <v-autocomplete
                      v-model="tool.client_id"
                      :disabled="tool.from_orders"
                      :items="clients"
                      :menu-props="{ maxHeight: '400' }"
                      :label="$t('tools.client')"
                      persistent-hint
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('status')"
                  >
                    <v-select
                      v-model="tool.status"
                      :items="statuses"
                      :menu-props="{ maxHeight: '400' }"
                      :label="$t('status')"
                      :hint="$t('select-hint')"
                      persistent-hint
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ decimal: true }"
                    :name="$t('tools.cost')"
                  >
                    <v-text-field
                      v-model="tool.cost"
                      :label="$t('tools.cost')"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <date-picker
                    v-model="tool.when_ordered"
                    :label="$t('tools.ordered_at')"
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <date-picker
                    v-model="tool.when_created"
                    :label="$t('tools.created_at')"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <date-picker
                    v-model="tool.when_delivered"
                    :label="$t('tools.delivered_at')"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('tools.supplier')"
                  >
                    <v-autocomplete
                      v-model="tool.supplier_id"
                      :items="suppliers"
                      :menu-props="{ maxHeight: '400' }"
                      :label="$t('tools.supplier')"
                      persistent-hint
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="1" md="1">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ decimal: true }"
                  >
                    <v-text-field
                      v-model="tool.dimension_x"
                      label="X"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="3" sm="1" md="1">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ decimal: true }"
                  >
                    <v-text-field
                      v-model="tool.dimension_y"
                      label="Y"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="3" sm="1" md="1">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ decimal: true }"
                  >
                    <v-text-field
                      v-model="tool.dimension_z"
                      label="Z"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="3" sm="1" md="1">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ between: { min: 1, max: 1000 } }"
                    :name="$t('tools.nesting')"
                  >
                    <v-text-field
                      v-model="tool.nesting"
                      :label="$t('tools.nesting')"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="pd in pds[tool.type]"
                  :key="pd.id"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <tool-param
                    v-model="pvs[pd.tool_type][pd.param_name]"
                    :param_definition="pd"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('tools.location')"
                  >
                    <v-text-field
                      v-model="tool.location"
                      :label="$t('tools.location')"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="onCancel()">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!isNew && invalid"
              text
              @click="onSave()"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-tab-item>

      <v-tab-item value="tab-attachments">
        <v-card elevation="0">
          <v-row>
            <attachments
              v-if="!isNew"
              ref="attachments"
              :items="attachments"
              :preview-url="previewUrl"
              permission="api.delete_toolattachment"
              :statuses="uploadStatuses"
              @error="onError"
              @upload="onUploadAttachments"
              @select="onAttachmentSelected"
              @delete="onDeleteAttachment"
              @close="onUploadAttachmentsClosed"
              @download-all="onDownloadAll"
            />
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-orders">
        <order-list :search-phrase="tool.name" limit />
      </v-tab-item>

      <v-tab-item value="tab-changelog">
        <change-log :item="value" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ToolService from '@/services/ToolService.js';
import Mixin from './toolsMixin.js';
import CustomerService from '@/services/CustomerService.js';
import ToolParam from './toolParam.vue';
import DatePicker from '@/components/base/DatePicker.vue';

export default {
  components: {
    ToolParam,
    DatePicker,
    attachments: () =>
      import('@/views/dashboard/components/files/attachmentList'),
    OrderList: () => import('@/views/dashboard/components/orders/orderList'),
    ChangeLog: () => import('./toolChangelog.vue')
  },
  mixins: [Mixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    customValidation: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      tool: { ...this.value },
      currentTab: 'tab-Tool',
      customers: [],

      attachments: null,
      uploadStatuses: [],
      previewUrl: '',

      pds: {}, // param definitions
      pvs: {}, // param values
      initialized: false
    };
  },
  computed: {
    isNew() {
      return !(this.tool.id > 0);
    },
    title() {
      if (this.isNew) {
        return this.$t('tools.new');
      }
      return this.$t('tools.edit', { tool: this.tool.name });
    },
    tabs() {
      var t = [{ key: 'tool', name: this.$t('tools.tool') }];

      if (!this.isNew) {
        t.push({ key: 'attachments', name: this.$t('attachments.title') });
        t.push({ key: 'orders', name: this.$t('tools.orders-with-tool') });
        t.push({ key: 'changelog', name: this.$t('tools.changelog.header') });
      }

      return t;
    },
    clients() {
      return this.customers
        .filter((c) => c.types.includes('Client'))
        .map((c) => {
          return {
            text: c.name,
            value: c.id
          };
        });
    },
    suppliers() {
      return this.customers
        .filter((c) => c.types.includes('Supplier'))
        .map((c) => {
          return {
            text: c.name,
            value: c.id
          };
        });
    }
  },
  watch: {
    value: {
      handler() {
        this.tool = { ...this.value };
      },
      deep: true
    }
  },
  async mounted() {
    console.log('tool form mounted; item:', this.tool);
    this.currentTab = 'tab-Tool';
    await this.init();
    this.focusInput();
  },
  async activated() {
    console.log('tool form activated; item:', this.tool);
    this.currentTab = 'tab-Tool';
    await this.init();
    this.focusInput();
  },
  methods: {
    async onCancel() {
      this.initialized = false;
      this.$refs.observer.reset();
      this.$emit('close');
    },

    async onSave() {
      const result = await this.$refs.observer.validate();
      if (!result) return;
      if (this.customValidation) {
        let err = this.customValidation(this.tool);
        if (err) {
          this.showError(this, err);

          return;
        }
      }
      this.updateParams();

      console.log('tool save: ', this.tool);
      try {
        if (this.isNew) {
          let result = await ToolService.postTool(this.tool);
          console.log('result: ', result);
          let t = result.data;
          // console.log("tool: ", t);
          t.when_created = this.dateFromISO8601(t.when_created);
          t.when_ordered = this.dateFromISO8601(t.when_ordered);
          t.when_delivered = this.dateFromISO8601(t.when_delivered);
          console.log('tool: ', t);
          this.$root.$emit('tool_added', t); // use central event hub to notify siblign container
          this.$emit('tool_added', t); // use central event hub to notify siblign container
        } else {
          let result = await ToolService.patchTool(this.tool);
          console.log('result: ', result);
          // Object.assign(this.tool, result.data);
          const tool = result.data;
          tool.when_created = this.dateFromISO8601(tool.when_created);
          tool.when_ordered = this.dateFromISO8601(tool.when_ordered);
          tool.when_delivered = this.dateFromISO8601(tool.when_delivered);
          this.$root.$emit('tool_updated', tool); // use central event hub to notify siblign container
        }
        this.initialized = false;
        this.$emit('close');
      } catch (err) {
        this.showError(this, err);
      }
    },

    updateParams() {
      let toolType = this.tool.type;
      if (
        this.pds.length == 0 ||
        !this.pds[toolType] ||
        this.pds[toolType].length == 0
      ) {
        this.tool.params = [];
        return;
      }

      this.pds[toolType].forEach((pd) => {
        let updated = false;
        this.tool.params.forEach((p) => {
          if (p.definition.id == pd.id) {
            console.log('updated');
            updated = true;
            p.value = this.pvs[toolType][pd.param_name];
            return;
          }
        });
        if (updated) {
          return;
        }

        this.tool.params.push({
          tool_id: this.tool.id,
          definition: pd,
          value: this.pvs[toolType][pd.param_name]
        });
      });

      // console.log('params to send:', this.tool.params);
    },

    async loadCustomers() {
      console.log('Loading customers');

      this.customers = await CustomerService.getCustomers([
        'Client',
        'Supplier'
      ]);
      // console.log('customers loaded:', this.customers);
    },

    async loadParamDefinitions() {
      console.log('Loading param definitions');
      this.pds = await ToolService.getParamDefinitions();

      // init starage for param values
      for (let tt in this.pds) {
        this.pvs[tt] = this.pvs[tt] || {};
        this.pds[tt].forEach((pd) => {
          this.pvs[tt][pd.param_name] = this.getParamValue(pd.id);
        });
      }

      console.log('pds:', this.pds);
      console.log('pvs:', this.pvs);
    },

    async loadAttachments() {
      if (this.isNew) {
        return;
      }
      console.log('Loading attachments');
      [this.attachments] = await ToolService.listAttachments(this.tool);
      console.log('attachments:', this.attachments);
    },

    getParamValue(definitionID) {
      for (let i in this.tool.params) {
        let p = this.tool.params[i];
        if (p.definition.id == definitionID) {
          return p.value;
        }
      }
      return '';
    },

    async init() {
      if (this.initialized) {
        console.log('init skipped (already initialized)');
      }
      this.initialized = true;
      this.previewUrl = '';
      this.attachments = [];
      try {
        await Promise.all([
          this.mixinInit(),
          this.loadCustomers(),
          this.loadParamDefinitions(),
          this.loadAttachments()
        ]);
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onUploadAttachments(files) {
      console.log('toolForm.onUploadAttachments:', files);
      await Promise.all(files.map((f) => this.uploadSingle(f)));
    },

    async onUploadAttachmentsClosed() {
      try {
        await this.loadAttachments();
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onDownloadAll() {
      console.log('toolForm.onDownloadAll');
      const keys = this.attachments.map((at) => at.path);
      try {
        const rsp = await ToolService.downloadAttachments(this.value, keys);

        let link = document.createElement('a');
        link.href = rsp.url;
        link.setAttribute('download', rsp.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.showError(this, err);
      }
    },

    async uploadSingle(f) {
      this.$set(this.uploadStatuses, f.id, 'uploading');
      try {
        await ToolService.uploadAttachment(this.tool, f.file);
        this.$set(this.uploadStatuses, f.id, 'done');
      } catch (err) {
        console.log('err:', err);
        const msg = err.message || err;
        this.$set(this.uploadStatuses, f.id, `error:${msg}`);
      }
    },

    async onAttachmentSelected(attachment) {
      if (!attachment) {
        this.previewUrl = '';
        return;
      }
      let url = await ToolService.downloadAttachment(
        this.tool,
        attachment.path
      );
      console.log('attachment url: ', url);
      this.previewUrl = url;
    },

    focusInput() {
      console.log('focusInput');
      this.$refs.name.focus();
    },

    onError(err) {
      this.showError(this, err);
    },
    async onDeleteAttachment(attachment) {
      console.log('delete attachment:', attachment);
      try {
        let rsp = await ToolService.deleteAttachment(this.tool, attachment);
        console.log('delete attachment.rsp:', rsp);
        this.$refs.attachments.deleted(attachment);
        this.attachments = this.attachments.filter(
          (at) => at.id != attachment.id
        );
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style></style>
