<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <v-tabs v-model="currentTab" fixed-tabs>
      <v-tab v-for="item in tabs" :key="item.key" :href="'#tab-' + item.key">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item value="tab-order">
        <v-card-text>
          <v-container>
            <validation-observer ref="observer" :name="$t('orders.priority')">
              <v-row>
                <v-col cols="6" sm="3" md="3">
                  <v-text-field
                    v-model="item.priority"
                    :label="$t('orders.priority')"
                    dense
                    hide-details
                    type="number"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('orders.order-no')"
                  >
                    <v-text-field
                      ref="order_no"
                      v-model="item.order_no"
                      :label="$t('orders.order-no')"
                      dense
                      :error-messages="errors"
                      :disabled="item.archived"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" sm="3" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('orders.client-no')"
                  >
                    <v-text-field
                      v-model="item.client_order_no"
                      :label="$t('orders.client-no')"
                      dense
                      :error-messages="errors"
                      :disabled="item.archived"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="6" sm="3" md="3">
                  <date-picker
                    v-model="item.when_created"
                    :label="$t('orders.created_at')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>

                <v-col cols="6" sm="3" md="3">
                  <date-picker
                    v-model="item.due_date"
                    :label="$t('orders.due-date')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('status')"
                  >
                    <v-select
                      v-model="item.status"
                      :items="statuses"
                      :menu-props="{ maxHeight: '400' }"
                      :label="$t('status')"
                      :hint="$t('select-hint')"
                      persistent-hint
                      dense
                      :disabled="item.archived"
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('orders.client-external-id')"
                  >
                    <v-text-field
                      v-model="item.client_id"
                      :label="$t('orders.client-external-id')"
                      dense
                      readonly
                      :error-messages="errors"
                      :disabled="item.archived"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-container>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|min:1"
                      :name="$t('orders.client')"
                    >
                      <v-autocomplete
                        v-model="item.client_id"
                        :items="clients"
                        :menu-props="{ maxHeight: '400' }"
                        :label="$t('orders.client')"
                        hint="select"
                        persistent-hint
                        :clearable="!item.archived"
                        dense
                        :error-messages="errors"
                        :disabled="item.archived"
                        @keydown.esc="onCancel"
                      >
                        <template slot="append-outer">
                          <v-btn
                            v-if="!item.archived"
                            class="mx-2"
                            fab
                            dark
                            small
                            color="accent"
                            @click="onNewClient"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn> </template
                        >\
                      </v-autocomplete>
                    </validation-provider>

                    <v-dialog v-model="newClientDialog" persistent>
                      <customer-form
                        v-bind="newClientParams"
                        @close="onClientDialogClose"
                        @customer_added="onNewCustomerAdded"
                      />
                    </v-dialog>
                  </v-container>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="6" md="4">
                  <v-text-field
                    v-model="item.item_no"
                    :label="$t('orders.item-no')"
                    dense
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="item.description"
                    :label="$t('orders.description')"
                    rows="2"
                    auto-grow
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-switch
                    v-model="item.new_die_cut"
                    :label="$t('orders.new-diecut')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="die_cut_required"
                    :name="$t('orders.diecut')"
                  >
                    <v-select
                      v-model="item.die_cut_id"
                      :items="die_cuts"
                      :menu-props="{ maxHeight: '400' }"
                      :label="$t('orders.diecut')"
                      hint="select"
                      persistent-hint
                      :disabled="
                        item.new_die_cut || !client_exists || item.archived
                      "
                      dense
                      :clearable="!item.archived"
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    >
                      <template slot="append-outer">
                        <v-btn
                          v-if="!item.archived"
                          :disabled="!client_exists"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="accent"
                          @click="onNewTool('die cutter', newDiecutName)"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="4" md="3">
                  <v-switch
                    v-model="item.duplex"
                    :label="$t('orders.duplex')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="6" sm="4" md="3">
                  <v-switch
                    v-model="item.pms"
                    :label="$t('orders.pms')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="6" sm="4" md="3">
                  <v-switch
                    v-model="item.serialization"
                    :label="$t('orders.serialization')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="4" md="3">
                  <v-switch
                    v-model="item.braille_enabled"
                    :label="$t('orders.braille')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="6" sm="4" md="3">
                  <v-switch
                    v-model="item.new_braille"
                    :disabled="!item.braille_enabled"
                    :label="$t('orders.new-braille')"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="braille_required"
                    :name="$t('orders.braille')"
                  >
                    <v-select
                      v-model="item.braille_id"
                      :items="brailles"
                      :menu-props="{ maxHeight: '400' }"
                      :disabled="
                        !item.braille_enabled || !client_exists || item.archived
                      "
                      :label="$t('orders.braille')"
                      hint="select"
                      persistent-hint
                      :clearable="!item.archived"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    >
                      <template slot="append-outer">
                        <v-btn
                          v-if="!item.archived"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="accent"
                          :disabled="!client_exists"
                          @click="onNewTool('braille stapler', newBrailleName)"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                    </v-select>
                  </validation-provider>
                  <v-dialog v-model="newToolDialog" persistent>
                    <tool-form
                      v-bind="newToolParams"
                      @close="onToolDialogClose"
                      @tool_added="onNewToolAdded"
                    />
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="item.braille_text"
                    :label="$t('orders.braille-text')"
                    rows="1"
                    auto-grow
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="4">
                  <v-text-field
                    v-model="item.cardboard_thickness"
                    :label="$t('orders.cardboard-thickness')"
                    :disabled="item.archived"
                    dense
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="6" sm="4" md="3">
                  <v-switch
                    v-model="item.varnish_free_area"
                    :label="$t('orders.field.varnish_free_area')"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
                <v-col cols="6" sm="4" md="3">
                  <v-text-field
                    ref="varnish_plate_number"
                    v-model="item.varnish_plate_number"
                    :disabled="!item.varnish_free_area || item.archived"
                    :label="$t('orders.field.varnish_plate_number')"
                    dense
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="3" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('orders.quantity')"
                  >
                    <v-text-field
                      v-model="item.quantity"
                      :label="$t('orders.quantity')"
                      type="number"
                      :disabled="item.archived"
                      dense
                      :error-messages="errors"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" sm="3" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:1"
                    :name="$t('orders.field.num_of_samples')"
                  >
                    <v-text-field
                      v-model="item.num_of_samples"
                      :label="$t('orders.field.num_of_samples')"
                      type="number"
                      dense
                      :error-messages="errors"
                      :disabled="item.archived"
                      @keydown.esc="onCancel"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="3" md="2">
                  <v-text-field
                    v-model="item.lot_no"
                    :label="$t('orders.field.lot_no')"
                    :disabled="item.archived"
                    dense
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="item.comments"
                    :label="$t('comments')"
                    auto-grow
                    rows="2"
                    :disabled="item.archived"
                    @keydown.esc="onCancel"
                  />
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!item.archived"
            color="blue darken-1"
            text
            @click="onReparse()"
          >
            {{ $t('orders.restore') }}
          </v-btn>
          <v-btn
            v-if="!item.archived"
            color="blue darken-1"
            text
            @click="onSave()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-tab-item>

      <v-tab-item value="tab-attachments">
        <v-card elevation="0">
          <v-row>
            <attachments
              v-if="!isNew"
              ref="attachments"
              :items="attachments"
              :preview-url="previewUrl"
              permission="api.delete_orderattachment"
              :statuses="uploadStatuses"
              :disable-upload="item.archived"
              :disable-delete="item.archived"
              @error="onError"
              @upload="onUploadAttachments"
              @select="onAttachmentSelected"
              @delete="onDeleteAttachment"
              @close="onUploadAttachmentsClosed"
              @download-all="onDownloadAll"
            />
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="onCancel()">
        {{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomerService from '@/services/CustomerService.js';
import ToolService from '@/services/ToolService.js';
import OrderService from '@/services/OrderService.js';
import CustomerForm from '../customers/customerForm.vue';
import ToolForm from '../tools/toolForm.vue';
import DatePicker from '@/components/base/DatePicker.vue';
import OrderMixin from './orderMixin.js';

export default {
  components: {
    CustomerForm,
    ToolForm,
    DatePicker,
    attachments: () =>
      import('@/views/dashboard/components/files/attachmentList')
  },
  mixins: [OrderMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      item: { ...this.value },
      currentTab: 'tab-order',
      originalItem: null,

      attachments: null,
      uploadStatuses: [],
      previewUrl: '',

      loaded_clients: [],
      last_tools_owner: null,
      client_die_cuts: [],
      client_brailles: [],
      newClientDialog: false,
      newClientParams: null,
      newToolDialog: false,
      newToolParams: null
    };
  },
  computed: {
    isNew() {
      return !(this.item.id > 0);
    },
    client() {
      return this.loaded_clients.find(
        (c) => c.external_id === this.item.client_id
      );
    },
    client_exists() {
      console.log('client_exists');
      return this.loaded_clients.some(
        (c) => c.external_id === this.item.client_id
      );
    },
    title() {
      if (this.isNew) {
        return this.$t('orders.new');
      }
      return this.$t('orders.edit', { order: this.item.order_no });
    },
    // client() {
    //   return this.item.client;
    // },
    clients() {
      let dc = this.loaded_clients.map((t) => {
        return {
          value: t.external_id,
          text: `${t.external_id} - ${t.name}`
        };
      });
      if (!dc.some((t) => t.name === this.item.client)) {
        dc.push({
          value: this.item.client_id,
          text: this.item.client_id + ' (Not Found)'
        });
      }

      return dc;
    },
    die_cuts() {
      let dc = this.client_die_cuts.map((t) => {
        return {
          value: t.name,
          text: t.name
        };
      });
      if (!dc.some((t) => t.name === this.item.die_cut_id)) {
        dc.push({
          value: this.item.die_cut_id,
          text: this.item.die_cut_id + ' (Not Found)'
        });
      }

      return dc;
    },
    newDiecutName() {
      if (this.client_die_cuts.some((dc) => dc.name == this.item.die_cut_id)) {
        return '';
      }
      return this.item.die_cut_id;
    },
    newBrailleName() {
      if (this.client_brailles.some((b) => b.name == this.item.braille_id)) {
        return '';
      }
      return this.item.braille_id;
    },
    brailles() {
      let b = this.client_brailles.map((t) => {
        return {
          value: t.name,
          text: t.name
        };
      });
      if (!b.some((t) => t.name === this.item.braille_id)) {
        b.push({
          value: this.item.braille_id,
          text: this.item.braille_id + ' (Not Found)'
        });
      }

      return b;
    },
    braille_required() {
      if (!this.item.braille_enabled) return '';
      if (this.item.new_braille) return '';
      return 'required|min:1';
    },
    die_cut_required() {
      if (this.item.new_die_cut) return '';
      return 'required|min:1';
    },
    tabs() {
      return [
        { key: 'order', name: this.$t('orders.order') },
        { key: 'attachments', name: this.$t('attachments.title') }
      ];
    }
  },
  watch: {
    value: {
      handler(val) {
        // console.log('val: ', val);
        this.item = { ...val };
      },
      deep: true
    },
    async client(client, old_client) {
      if (client === old_client) return;
      console.log('client changed:', client, 'old:', old_client);
      if (client) {
        await this.loadTools(client, true);
      }
    }
  },
  async activated() {
    console.log('activated');
    await this.init();
  },
  methods: {
    async loadAttachments() {
      if (!this.item.id) return;
      console.log('Loading attachments');
      [this.attachments] = await OrderService.listAttachments(this.item);
      console.log('attachments:', this.attachments);
    },

    async loadCustomers() {
      console.log('Loading customers');

      this.loaded_clients = await CustomerService.getCustomers(['Client']);
    },

    async loadTools(customer_external_id, force = false) {
      console.log(
        'loadTools(customer_external_id=',
        customer_external_id,
        ', force=',
        force
      );
      // external_id => customer_id
      // let customer = this.loaded_clients.find(
      //   c => c.external_id === customer_external_id
      // );
      const customer = this.client;
      console.log('customer:', customer);
      if (!customer) return;

      console.log('loadTools, customer_id:', customer.id);
      // don't load twice
      // it is not only inefficient, but also causes
      // issues with autocomplete component (sometimes element is not selected)
      // if (!force && this.last_tools_owner == customer.id && customer.id) return;

      this.last_tools_owner = customer.id;

      this.client_die_cuts = [];
      this.client_brailles = [];

      if (customer.id) {
        console.log('Loading tools; customer_id=', customer.id);

        let [response] = await ToolService.getTools(
          null,
          null,
          'die cutter|braille stapler',
          customer.id
        );
        console.log('tools loaded:', response);

        this.client_die_cuts = response.filter((t) => t.type == 'die cutter');

        this.client_brailles = response.filter(
          (t) => t.type == 'braille stapler'
        );
      }

      console.log('[1] client_die_cuts:', this.client_die_cuts);
      console.log('[1] client_brailles:', this.client_brailles);
    },

    async init() {
      console.log('init(); item:', this.item);
      this.previewUrl = '';
      this.attachments = [];
      this.currentTab = 'tab-order';
      this.originalItem = Object.assign({}, this.item);
      this.focusInput();

      try {
        await this.loadCustomers();
        // tools loading is depending on customers
        await this.loadTools(this.item.client);
        await this.loadAttachments();
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onCancel() {
      if (JSON.stringify(this.item) === JSON.stringify(this.originalItem)) {
        this.$refs.observer.reset();
        this.$emit('close');
        return;
      }

      const res = await this.$swal({
        html: this.$t('cancel-edit-confirm'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        console.log('dismissed');
        return;
      }
      this.$refs.observer.reset();
      this.$emit('close');
    },

    async onReparse() {
      try {
        let result = await OrderService.reparseOrder(this.item);
        let order = result.data;
        order.when_created = this.dateFromISO8601(order.when_created);
        order.due_date = this.dateFromISO8601(order.due_date);
        this.item.status = order.status;
        this.item.when_created = order.when_created;
        this.item.client = order.client;
        this.item.client = order.client;
        this.item.client = order.client;
        this.item.order_no = order.order_no;
        this.item.client_order_no = order.client_order_no;
        this.item.due_date = order.due_date;
        this.item.item_no = order.item_no;
        this.item.description = order.description;
        this.item.die_cut = order.die_cut;
        this.item.die_cut_ro = order.die_cut_ro;
        this.item.new_die_cut = order.new_die_cut;
        this.item.duplex = order.duplex;
        this.item.braille_enabled = order.braille_enabled;
        this.item.braille = order.braille;
        this.item.braille_ro = order.braille_ro;
        this.item.braille_text = order.braille_text;
        this.item.new_braille = order.new_braille;
        this.item.cardboard_thickness = order.cardboard_thickness;
        this.item.varnish_free_area = order.varnish_free_area;
        this.item.varnish_plate_number = order.varnish_plate_number;
        this.item.pms = order.pms;
        this.item.quantity = order.quantity;
        this.item.serialization = order.serialization;
        this.item.comments = order.comments;
        this.item.created_at = order.created_at;
        this.item.updated_at = order.updated_at;
        console.log('item restored: ', this.item);
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onSave() {
      // console.log("die_cut=", this.die_cut)
      const result = await this.$refs.observer.validate();
      if (!result) {
        console.log('validation failed: ', result);
        return;
      }

      try {
        let obj = Object.assign({}, this.item);
        if (obj.due_date) {
          obj.due_date.setHours(23, 59, 59, 999);
          obj.due_date = obj.due_date.toISOString();
        }

        // update via braille_id, die_cut_id to avoid db constraint checking
        delete obj.client;
        delete obj.client_ro;
        delete obj.die_cut;
        delete obj.braille;

        if (this.isNew) {
          let result = await OrderService.postOrder(obj);
          let order = result.data;
          order.when_created = this.dateFromISO8601(order.when_created);
          order.due_date = this.dateFromISO8601(order.due_date);
          console.log('result: ', order);
          this.$root.$emit('order_added', order); // use central event hub to notify siblign container
        } else {
          let result = await OrderService.patchOrder(obj);
          let order = result.data;
          order.when_created = this.dateFromISO8601(order.when_created);
          order.due_date = this.dateFromISO8601(order.due_date);
          console.log('result: ', order);
          this.$root.$emit('order_updated', order); // use central event hub to notify siblign container
        }
        this.$emit('close');
      } catch (err) {
        this.showError(this, err);
      }
    },
    focusInput() {
      setTimeout(() => {
        this.$refs.order_no.focus();
      }, 200);
    },

    async onNewClient() {
      let custTypes = await CustomerService.getCustomerTypes();

      this.newClientParams = {
        value: {
          id: '',
          name: '',
          short_name: '',
          types: ['Client'],
          external_id: this.originalItem.client_id,
          nip: '',
          regon: '',
          due_days: null,
          default_varnish: '',
          addresses: []
        },
        types: custTypes,
        customValidation: (item, types) => {
          let wrongType = "'Client' type is required";
          let typ = types.find((t) => t.name == 'Client');
          if (!typ) return wrongType;

          if (!item.types.some((t) => t == typ.name)) {
            return wrongType;
          }

          return null;
        }
      };
      this.newClientDialog = true;
    },

    async onNewTool(kind, name) {
      console.log('onNewTool(', kind, ')');

      let types = await this.$store.dispatch('getToolTypes');
      let wrongType = `"${kind}" type is required`;
      let typ = types.find((t) => t.name == kind);
      if (!typ) {
        console.log('internal error: unknown tool type: ', kind);
        return wrongType;
      }

      this.newToolParams = {
        item: {
          id: '',
          name: name,
          type: typ.name,
          status: '',
          client_id: this.client.id,
          cost: 0,
          when_created: new Date(),
          when_ordered: new Date(),
          when_delivered: new Date(),
          supplier: '',
          dimension_x: 0,
          dimension_y: 0,
          dimension_z: 0,
          nesting: 1,
          location: '',
          params: [],
          from_orders: true
        },
        types: types,
        customValidation: (item) => {
          console.log('item.type:', item.type);
          console.log('typ.id:', typ.id);

          if (item.type != typ.name) {
            return wrongType;
          }

          return null;
        }
      };
      this.newToolDialog = true;
    },

    onClientDialogClose() {
      this.newClientDialog = false;
    },

    onToolDialogClose() {
      this.newToolDialog = false;
    },

    async onNewCustomerAdded(customer) {
      console.log('onCustomerAdded:', customer);
      await this.loadCustomers();
      if (this.item.client) {
        await this.loadTools(this.item.client);
      }
    },

    async onNewToolAdded(tool) {
      console.log('onToolAdded:', tool);
      await this.loadTools(this.item.client_id, true);
      if (tool.type == 'braille stapler') {
        this.item.braille = tool.name;
      } else if (tool.type == 'die cutter') {
        this.item.die_cut = tool.name;
      }
    },

    onError(err) {
      this.showError(this, err);
    },

    async onUploadAttachments(files) {
      console.log('orderForm.onUploadAttachments:', files);
      await Promise.all(files.map((f) => this.uploadSingle(f)));
    },

    async onUploadAttachmentsClosed() {
      try {
        await this.loadAttachments();
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onDownloadAll() {
      console.log('orderForm.onDownloadAll');
      const keys = this.attachments.map((at) => at.path);
      try {
        const rsp = await OrderService.downloadAttachments(this.item, keys);

        let link = document.createElement('a');
        link.href = rsp.url;
        link.setAttribute('download', rsp.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.showError(this, err);
      }
    },

    async uploadSingle(f) {
      this.$set(this.uploadStatuses, f.id, 'uploading');
      try {
        await OrderService.uploadAttachment(this.item, f.file);
        this.$set(this.uploadStatuses, f.id, 'done');
      } catch (err) {
        console.log('err:', err);
        const msg = err.message || err;
        this.$set(this.uploadStatuses, f.id, `error:${msg}`);
      }
    },

    async onAttachmentSelected(attachment) {
      if (!attachment) {
        this.previewUrl = '';
        return;
      }
      try {
        let url = await OrderService.downloadAttachment(
          this.item,
          attachment.path
        );
        console.log('attachment url: ', url);
        this.previewUrl = url;
      } catch (err) {
        this.previewUrl = '';
        this.showError(this, err);
      }
    },

    async onDeleteAttachment(attachment) {
      console.log('delete attachment:', attachment);
      try {
        let rsp = await OrderService.deleteAttachment(this.item, attachment);
        console.log('delete attachment.rsp:', rsp);
        this.$refs.attachments.deleted(attachment);
        this.attachments = this.attachments.filter(
          (at) => at.id != attachment.id
        );
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style></style>
